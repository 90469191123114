import {Id, YN} from '../types';


////////////////
// Interfaces //
export interface IMinimalAddress {
  street: string;
  city: string;
  country?: string;

  lat?: number;
  lng?: number;
}

export interface ICreateAddress extends IMinimalAddress {
  name?: string;
  invoice: YN,
  favorite: YN
}

export interface IUpdateAddress extends ICreateAddress {
  id?: Id,
  client?: Id
}


export interface IAddressRecord extends ICreateAddress {
  id?: Id
}

export interface IAddress extends ICreateAddress {
  id?: Id;
  client?: Id
}


///////////////
// functions //
export const emptyMinimalAddress: IMinimalAddress = {
  lat: 0, lng: 0,
  street: '', city: '',
  country: 'BE'
};
export const emptyCreateAddress: ICreateAddress = {
  name: '',
  street: '', city: '', country: 'BE',
  lat: 0, lng: 0,
  favorite: YN.kNo, invoice: YN.kNo
};

export function getEmptyClientAddress(clientId: Id, invoice = YN.kNo, favorite = YN.kNo): IAddress {
  return {
    name: '',
    client: clientId,
    ...emptyMinimalAddress,
    invoice,
    favorite
  }
}

export function hasEmptyAddress(address: IMinimalAddress): boolean {
  return !address.street?.trim() && !address.city?.trim();
}

export function isSameAddressLocation(a: IMinimalAddress, b: IMinimalAddress): boolean {
  return a.street === b.street && a.city === b.city && a.country === b.country;
}

/////////////////////////
// Rules for Addresses //
/////////////////////////

// Deprecated rules !!
// 1) Every client always have 2 addresses
// 2) The first is an InvoiceAddress
// 3) The second is the favorite YardAddress
// 4) Optionally followed by more YardAddresses
// 5) There can only be 1 InvoiceAddress
// 6) There always is 1 favorite YardAddress
// 7) An InvoiceAddress can not be the favorite address
// 8) The indicator separateInvoiceAddress is calculated/not stored and only for visualisation
//
// New rules as of 23 october 2024
// 1) Every client always has exactly 1 address with favorite = Y and 1 with invoice = Y, an address can have both to Y


// [enforce rules]
export function fixAddresses<T extends (ICreateAddress | IUpdateAddress | IAddress)>(addresses: Array<T>, clientId?: Id): Array<IAddress> {
  if (!addresses.length) return [getEmptyClientAddress(clientId || 0, YN.kYes, YN.kYes)];

  // set exactly 1 address to invoice=Y
  if (!addresses.find(x => x.invoice === YN.kYes)) addresses[0].invoice = YN.kYes;
  else addresses.filter(x => x.invoice === YN.kYes).forEach((x, inx) => {if (inx) x.invoice = YN.kNo});

  // lset exactly 1 address to favorite=Y
  if (!addresses.find(x => x.favorite === YN.kYes)) addresses[0].favorite = YN.kYes;
  else addresses.filter(x => x.favorite === YN.kYes).forEach((x, inx) => {if (inx) x.favorite = YN.kNo});

  // set client-id if given
  return addresses.map(x => {
    if (clientId) (x as IAddress).client = clientId;
    return x;
  });
}
