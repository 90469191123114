import { groupBy } from "../helpers/array";
import { Lazy, lazy } from "../helpers/lazy";
import { capitalize } from "../helpers/string";
import { HttpStatusCode, Nullable, RefType, ServerResponse, ServerStatus } from "../types";
import { IClient } from "./client";
import { IEmployee } from "./employee";
import { IItem } from "./item";
import { ICompanyLicensePlansIssue } from "./licenses";
import { ISnippetRecord } from "./snippet";

////////////////////////////////////////
// Types
////////////////////////////////////////

type ErrorServerStatus =
  | ServerStatus.kError
  | ServerStatus.kNOK;

type HttpErrorStatusCode =
  | HttpStatusCode.kBadRequest
  | HttpStatusCode.kNotAuthenticated
  | HttpStatusCode.kPaymentRequired
  | HttpStatusCode.kForbidden
  | HttpStatusCode.kNotfound
  | HttpStatusCode.kConflict
  | HttpStatusCode.kPreConditionFailed
  | HttpStatusCode.kTooManyRequests
  | HttpStatusCode.kInternalError
  | HttpStatusCode.kServiceUnavailable;

export interface IKnownErrorDef<T = any> {
  readonly code: string;
  readonly domain: ErrorDomain;
  readonly message: string;
  readonly statusCode: HttpErrorStatusCode;
  readonly data?: T;
}

interface IKnownErrorOptions<T> {
  message?: Nullable<string>;
  data?: T;
  headers?: Record<string, string>;
}

export interface IKnownError<T = any> extends Error, IKnownErrorDef<T> {
  message: string;
  toResponse(): IKnownErrorResponse<T>;
}

export interface IKnownErrorResponse<T = any> extends ServerResponse, IKnownErrorDef<T> {
  readonly code: string;
  readonly message: string;
  readonly status: ErrorServerStatus;
  readonly statusCode: HttpErrorStatusCode;
  body: string;
}

////////////////////////////////////////
// Constants
////////////////////////////////////////

const kKnownErrorsByCode: ReadonlyMap<string, IKnownErrorDef> = new Map();
const kKnownErrorsByDomain: Lazy<ReadonlyMap<ErrorDomain, ReadonlyArray<IKnownErrorDef>>> = lazy(() =>
  groupBy(getAllKnownErrorDefs(), e => [e.domain, e], {freeze: true})
);

export enum ErrorDomain {
  kGlobal = 'global',
  kClients = 'clients',
  kComments = 'comments',
  kCompany = 'company',
  kCompanySubscription = 'company-subscription',
  kClient = 'client',
  kEmployee = 'employee',
  kItem = 'item',
  kLicenses = 'licenses',
  kLicensePlans = 'license-plans',
  kUser = 'user',
  kUserFiles = 'user-files',
  kPlanning = 'planning',
  kMail = 'mail',
  kSnippets = 'snippets'
}

export const KnownError = Object.freeze({
  // Global
  not_found:                                defineKnownError<{id?: number; refType?: RefType; [n:string]: number | string | undefined;}>(ErrorDomain.kGlobal, 'not-found', 'Not found', HttpStatusCode.kNotfound),
  not_allowed:                              defineKnownError(ErrorDomain.kGlobal, 'not-allowed', 'Not allowed', HttpStatusCode.kForbidden),
  not_your_company:                         defineKnownError(ErrorDomain.kGlobal, 'not-your-company', 'Not your company', HttpStatusCode.kForbidden),
  no_company:                               defineKnownError(ErrorDomain.kGlobal, 'no-company', 'No company', HttpStatusCode.kForbidden),
  invalid_access_level:                     defineKnownError(ErrorDomain.kGlobal, 'invalid-access-level', 'Invalid access level', HttpStatusCode.kBadRequest),
  invalid_id:                               defineKnownError(ErrorDomain.kGlobal, 'invalid-id', 'Invalid entity id', HttpStatusCode.kBadRequest),
  invalid_vat_number:                       defineKnownError(ErrorDomain.kGlobal, 'invalid-vat-number', 'Invalid VAT number', HttpStatusCode.kBadRequest),
  unknown_domain:                           defineKnownError(ErrorDomain.kGlobal, 'unknown-domain', 'Unknown domain', HttpStatusCode.kBadRequest),
  unknown_feature:                          defineKnownError(ErrorDomain.kGlobal, 'unknown-feature', 'Unknown feature', HttpStatusCode.kBadRequest),
  canceled:                                 defineKnownError(ErrorDomain.kGlobal, 'canceled', 'Operation was canceled', HttpStatusCode.kInternalError),
  timeout:                                  defineKnownError(ErrorDomain.kGlobal, 'timeout', 'Operation has timed out', HttpStatusCode.kServiceUnavailable),

  // Client
  client_is_used:                           defineKnownError<{id: number}>(ErrorDomain.kClient, 'client-is-used', 'Client has documents', HttpStatusCode.kConflict),
  client_nr_is_used:                        defineKnownError<{nr: string; usedBy?: Nullable<IClient>;}>(ErrorDomain.kClient, 'client-nr-is-used', 'Client number is already in use', HttpStatusCode.kConflict),

  // Company
  insufficient_user_licenses:               defineKnownError(ErrorDomain.kCompany, 'insufficient-user-licenses', 'Insufficient user licenses to create or invite another user', HttpStatusCode.kPaymentRequired),
  too_many_mails_per_min:                   defineKnownError<{max:number}>(ErrorDomain.kCompany, 'too-many-mails-per-min', 'Too many mails sent per minute', HttpStatusCode.kTooManyRequests),
  too_many_mails_per_day:                   defineKnownError<{max:number}>(ErrorDomain.kCompany, 'too-many-mails-per-day', 'Too many mails sent per day', HttpStatusCode.kTooManyRequests),

  // Employee
  employee_is_used:                         defineKnownError<{id: number}>(ErrorDomain.kEmployee, 'employee-is-used', 'Employee has plannings or documents', HttpStatusCode.kConflict),
  employee_nr_is_used:                      defineKnownError<{nr: string; usedBy?: Nullable<IEmployee>}>(ErrorDomain.kEmployee, 'employee-nr-in-use', 'Employee number is already in use', HttpStatusCode.kConflict),
  employee_is_linked:                       defineKnownError<{user: number;}>(ErrorDomain.kEmployee, 'employee-is-linked', 'Employee is linked to a user', HttpStatusCode.kConflict),
  employee_already_linked:                  defineKnownError<{user: number; linkedTo?: Nullable<IEmployee>}>(ErrorDomain.kEmployee, 'employee-already-linked', 'Employee is already linked to a user', HttpStatusCode.kConflict),

  // Item
  item_code_is_used:                        defineKnownError<{code: string; usedBy?: Nullable<IItem>;}>(ErrorDomain.kItem, 'item-code-in-use', 'Item code is already in use', HttpStatusCode.kConflict),
  item_is_used:                             defineKnownError<{id: number}>(ErrorDomain.kItem, 'item-is-used', 'Item is in use', HttpStatusCode.kConflict),

  // Mail
  no_recipients:                            defineKnownError(ErrorDomain.kMail, 'no-recipients', 'No recipients provided', HttpStatusCode.kBadRequest),

  // Client-Addresses
  address_in_use:                           defineKnownError(ErrorDomain.kClients, 'address-in-use', 'Address in use', HttpStatusCode.kConflict),

  // Planning
  plan_item_not_found:                      defineKnownError(ErrorDomain.kPlanning, 'plan-item-not-found', 'Plan item not found', HttpStatusCode.kNotfound),

  // Snippets
  snippet_code_is_used:                     defineKnownError<{code: string; usedBy?: Nullable<ISnippetRecord>;}>(ErrorDomain.kSnippets, 'snippet-code-is-used', 'Snippet code is already in use', HttpStatusCode.kConflict),

  // Software Licenses
  license_not_found:                        defineKnownError(ErrorDomain.kLicenses, 'license-not-found', 'License not found', HttpStatusCode.kNotfound),
  license_is_used:                          defineKnownError(ErrorDomain.kLicenses, 'license-is-used', 'Cannot delete license while it is in use', HttpStatusCode.kConflict),
  license_features_removed_while_used:      defineKnownError(ErrorDomain.kLicenses, 'license-features-removed-while-used', 'Cannot remove features while license is in use', HttpStatusCode.kConflict),
  license_storage_too_small:                defineKnownError(ErrorDomain.kLicenses, 'license-storage-too-small', 'License storage must be >= 0', HttpStatusCode.kBadRequest),
  license_storage_reduced_while_used:       defineKnownError(ErrorDomain.kLicenses, 'license-storage-reduced-while-used', 'Cannot reduce storage while license is in use', HttpStatusCode.kConflict),
  license_singleton_changed_while_used:     defineKnownError(ErrorDomain.kLicenses, 'license-singleton-changed-while-used', 'Cannot switch license to singleton status while license is in use', HttpStatusCode.kConflict),
  license_type_changed:                     defineKnownError(ErrorDomain.kLicenses, 'license-type-changed', 'Cannot change license type', HttpStatusCode.kBadRequest),
  license_version_too_small:                defineKnownError(ErrorDomain.kLicenses, 'license-version-too-small', 'License version must be > 0', HttpStatusCode.kBadRequest),
  bundle_license_not_singleton:             defineKnownError(ErrorDomain.kLicenses, 'bundle-license-not-singleton', 'Bundle license must be a singleton license', HttpStatusCode.kBadRequest),
  original_license_not_found:               defineKnownError(ErrorDomain.kLicenses, 'original-license-not-found', 'Original license not found', HttpStatusCode.kNotfound),
  original_license_different_type:          defineKnownError(ErrorDomain.kLicenses, 'original-license-different-type', 'Original license must be of the same type', HttpStatusCode.kBadRequest),
  original_license_required:                defineKnownError(ErrorDomain.kLicenses, 'original-license-required', 'Original license ID is required for versioned licenses', HttpStatusCode.kBadRequest),
  original_license_provided:                defineKnownError(ErrorDomain.kLicenses, 'original-license-provided', 'Original license must be null for singleton licenses', HttpStatusCode.kBadRequest),
  user_license_required:                    defineKnownError(ErrorDomain.kLicenses, 'user-license-required', 'User license ID is required for bundle licenses', HttpStatusCode.kBadRequest),
  user_license_not_found:                   defineKnownError(ErrorDomain.kLicenses, 'user-license-not-found', 'User license not found', HttpStatusCode.kNotfound),
  user_license_type_invalid:                defineKnownError(ErrorDomain.kLicenses, 'user-license-type-invalid', `User license must be of type 'user'`, HttpStatusCode.kBadRequest),
  user_license_not_active:                  defineKnownError(ErrorDomain.kLicenses, 'user-license-not-active', 'User license is not active', HttpStatusCode.kBadRequest),
  user_license_is_singleton:                defineKnownError(ErrorDomain.kLicenses, 'user-license-is-singleton', 'User license cannot be a singleton license', HttpStatusCode.kBadRequest),
  user_license_is_used:                     defineKnownError(ErrorDomain.kLicenses, 'user-license-is-used', 'Cannot delete or update a user license while it is in use', HttpStatusCode.kConflict),
  user_license_changed_while_used:          defineKnownError(ErrorDomain.kLicenses, 'user-license-changed-while-used', 'Cannot change user license while it is in use', HttpStatusCode.kConflict),

  // Software License Plans
  license_plan_not_found:                   defineKnownError(ErrorDomain.kLicensePlans, 'license-plan-not-found', 'License plan not found', HttpStatusCode.kNotfound),
  license_plan_is_used:                     defineKnownError(ErrorDomain.kLicensePlans, 'license-plan-is-used', 'Cannot delete license plan while it is in use', HttpStatusCode.kConflict),
  license_plan_amount_invalid:              defineKnownError(ErrorDomain.kLicensePlans, 'license-plan-amount-invalid', 'License plan amount must be a valid number', HttpStatusCode.kBadRequest),
  license_plan_amount_too_small:            defineKnownError(ErrorDomain.kLicensePlans, 'license-plan-amount-too-small', 'License plan amount must be >= 0', HttpStatusCode.kBadRequest),
  license_plan_interval_count_too_small:    defineKnownError(ErrorDomain.kLicensePlans, 'license-plan-interval-count-too-small', 'License plan interval count must be > 0', HttpStatusCode.kBadRequest),
  license_plan_interval_already_active:     defineKnownError(ErrorDomain.kLicensePlans, 'license-plan-interval-already-active', 'A plan with the same interval and interval count is already active for this license', HttpStatusCode.kConflict),
  license_plan_interval_changed:            defineKnownError(ErrorDomain.kLicensePlans, 'license-plan-interval-count-changed', 'Cannot change license plan interval count after it has been used once', HttpStatusCode.kConflict),
  license_plan_amount_changed:              defineKnownError(ErrorDomain.kLicensePlans, 'license-plan-amount-changed', 'Cannot change license plan amount after it has been used once', HttpStatusCode.kConflict),
  user_license_plan_not_found:              defineKnownError(ErrorDomain.kLicensePlans, 'user-license-plan-not-found', 'No active user license plan found with same interval as the bundle license plan.', HttpStatusCode.kNotfound),
  no_active_license_plans_found:            defineKnownError(ErrorDomain.kLicensePlans, 'no-active-license-plans-found', 'No active license plans found', HttpStatusCode.kInternalError),

  // Company Subscriptions
  company_subscription_change_in_progress:  defineKnownError(ErrorDomain.kCompanySubscription, 'company-subscription-change-in-progress', 'Company subscription change is already in progress', HttpStatusCode.kConflict),
  company_subscription_validation_failed:   defineKnownError<ICompanyLicensePlansIssue[]>(ErrorDomain.kCompanySubscription, 'company-subscription-validation-failed', `One or more issues have been found with company's subscription plans.`, HttpStatusCode.kConflict),

  // User Files
  user_file_not_supported:                  defineKnownError(ErrorDomain.kUserFiles, 'user-file-not-supported', 'User file with given reftype and kind is not supported', HttpStatusCode.kBadRequest),
  user_file_not_found:                      defineKnownError(ErrorDomain.kUserFiles, 'user-file-not-found', 'User file not found', HttpStatusCode.kNotfound),
  user_file_not_legacy:                     defineKnownError(ErrorDomain.kUserFiles, 'user-file-not-legacy', 'User file is not a legacy file', HttpStatusCode.kBadRequest),
  user_file_not_audio:                      defineKnownError(ErrorDomain.kUserFiles, 'user-file-not-audio', 'User file is not an audio file', HttpStatusCode.kBadRequest),
  user_file_not_image:                      defineKnownError(ErrorDomain.kUserFiles, 'user-file-not-image', 'User file is not an image', HttpStatusCode.kBadRequest),
  user_file_infected:                       defineKnownError<{infection?: string}>(ErrorDomain.kUserFiles, 'user-file-infected', 'User file is infected', HttpStatusCode.kForbidden),
  files_not_deleted:                        defineKnownError<any[]>(ErrorDomain.kUserFiles, 'files-not-deleted', 'Some files could not be deleted', HttpStatusCode.kConflict),
  file_too_large:                           defineKnownError<{max: number;}>(ErrorDomain.kUserFiles, 'file-too-large', 'File is too large', HttpStatusCode.kBadRequest),
  insufficient_storage:                     defineKnownError<{remaining: number; required: number;}>(ErrorDomain.kUserFiles, 'insufficient-storage', 'Insufficient storage', HttpStatusCode.kPaymentRequired),

  // Comments
  comment_not_found:                        defineKnownError<{id: number;}>(ErrorDomain.kComments, 'comment-not-found', 'Comment not found', HttpStatusCode.kNotfound),
  comment_not_latest:                       defineKnownError(ErrorDomain.kComments, 'comment-not-latest', 'You can only update his latest post in the thread.', HttpStatusCode.kConflict),
  comment_not_yours:                        defineKnownError(ErrorDomain.kComments, 'comment-not-yours', 'Comment does not belong to you.', HttpStatusCode.kForbidden),
  comment_empty:                            defineKnownError(ErrorDomain.kComments, 'comment-empty', 'Comment is empty.', HttpStatusCode.kBadRequest),
  comment_too_long:                         defineKnownError<{max: number;}>(ErrorDomain.kComments, 'comment-too-long', 'Comment is too long.', HttpStatusCode.kBadRequest),
  too_many_comments:                        defineKnownError<{max: number;}>(ErrorDomain.kComments, 'too-many-comments', 'Too many consecutive comments in the thread.', HttpStatusCode.kBadRequest),
});

////////////////////////////////////////
// Functions
////////////////////////////////////////

function defineKnownError<T>(domain: ErrorDomain, code: string, message?: string, statusCode: HttpErrorStatusCode = HttpStatusCode.kInternalError, data?: T): IKnownErrorDef<T> {
  if (!code) throw new Error('code is required');
  if (kKnownErrorsByCode.has(code)) {
    throw new Error(`Known error code already defined: ${code}`);
  } else if (!message) {
    message = capitalize(code.replace(/\W+/g, ' ').trim());
  }
  const def = Object.freeze({code, domain, message, statusCode, data});
  (kKnownErrorsByCode as Map<string, IKnownErrorDef>).set(code, def);
  return def;
}

/**
 * Iterates over all known error definitions.
 */
export function allKnownErrorDefs(): Iterable<IKnownErrorDef> {
  return kKnownErrorsByCode.values();
}

/**
 * Iterates over all known error codes.
 */
export function allKnownErrorCodes(): Iterable<string> {
  return kKnownErrorsByCode.keys();
}

/**
 * Iterates over all known error definitions in the given domain.
 * @param domain the target error domain
 */
export function allKnownErrorsInDomain(domain: ErrorDomain): Iterable<IKnownErrorDef> {
  return kKnownErrorsByDomain.value.get(domain) || [];
}

/**
 * Gets a list of all known error definitions.
 */
export function getAllKnownErrorDefs(): IKnownErrorDef[] {
  return Array.from(kKnownErrorsByCode.values());
}

/**
 * Gets a list of all known error codes.
 */
export function getAllKnownErrorCodes(): string[] {
  return Array.from(kKnownErrorsByCode.keys());
}

/**
 * Gets a list of all known error definitions in the given domain.
 * @param domain the target error domain
 */
export function getAllKnownErrorsInDomain(domain: string): IKnownErrorDef[] {
  const defs = kKnownErrorsByDomain.value.get(domain as ErrorDomain);
  return defs ? Array.from(defs) : [];
}

/**
 * Gets a known error definition by its code.
 * @param code
 */
export function getKnownErrorDef(code: string): IKnownErrorDef | undefined {
  return kKnownErrorsByCode.get(code);
}

/**
 * Determines if the given error is a known error.
 * If a known error definition is provided, the error codes must match.
 * @param error The error to check.
 * @param def Optional known error definition to match against.
 */
export function isKnownError<T = any>(error: unknown, def?: IKnownErrorDef<T>): error is IKnownError<T> {
  return (
    !!error &&
    typeof error === 'object' &&
    error instanceof ThrowableKnownError &&
    (!def || error.code === def.code)
  );
}

/**
 * Determines if the given error code is a known error code.
 * @param code
 */
export function isKnownErrorCode(code: string): boolean {
  return kKnownErrorsByCode.has(code);
}

/**
 * Determines if the given response is a known error response.
 * If a known error definition is provided, the error codes must match.
 * @param response The response to check.
 * @param def Optional known error definition to match against.
 */
export function isKnownErrorResponse<T = any>(response: ServerResponse, def?: IKnownErrorDef<T>): response is IKnownErrorResponse<T> {
  return (
    (response.status === ServerStatus.kError ||
      response.status === ServerStatus.kNOK) &&
    (typeof response.code === 'string') &&
    kKnownErrorsByCode.has(response.code) &&
    (!def || response.code === def.code)
  );
}

/**
 * Asserts that the given condition is true or throw a known error if false.
 * @param condition The condition to assert.
 * @param def The definition of the known error to throw if the condition is false.
 * @param options.data Optional data to include in the error.
 * @param options.message Optional message to override the default message.
 */
export function assertKnownInvariant<T>(condition: unknown, def: IKnownErrorDef<T>, options?: IKnownErrorOptions<T>): asserts condition {
  if (!condition) throwKnownError(def, options);
}

/**
 * Creates a known error instance.
 * @param def The known error definition.
 * @param options.data Optional data to include in the error.
 * @param options.message Optional message to override the default message.
 */
export function createKnownError<T>(def: IKnownErrorDef<T>, options?: IKnownErrorOptions<T>): IKnownError<T> {
  return new ThrowableKnownError(def, options?.message, options?.headers, options?.data);
}

/**
 * Returns a known error response.
 * @param def The known error definition.
 * @param options.data Optional data to include in the response.
 * @param options.message Optional message to override the default message.
 * @param options.status Optional status to override the default status.
 */
export function resultKnownError<T>(def: IKnownErrorDef<T>, options?: {message?: Nullable<string>, data?: T, status?: ErrorServerStatus, headers?: Record<string, string>}): IKnownErrorResponse<T> {
  const resp = {
    code: def.code, domain: def.domain,
    message: options?.message || def.message,
    status: options?.status || ServerStatus.kError,
    statusCode: def.statusCode,
    data: options?.data ?? def.data
  } as IKnownErrorResponse<T>;
  resp.body = JSON.stringify(resp);
  resp.headers = options?.headers ?? {};
  resp.headers['Content-Type'] = 'application/json';
  return resp;
}

/**
 * Throws a known error.
 * @param def The known error definition.
 * @param options.data Optional data to include in the error.
 * @param options.message Optional message to override the default message.
 */
export function throwKnownError<T>(def: IKnownErrorDef<T>, options?: IKnownErrorOptions<T>): never {
  throw new ThrowableKnownError(def, options?.message, options?.headers, options?.data);
}

class ThrowableKnownError<T = any> extends Error implements IKnownError<T> {
  readonly code: string;
  readonly domain: ErrorDomain;
  readonly headers?: Record<string, string>;
  readonly statusCode: HttpErrorStatusCode;
  readonly data?: T;

  constructor(def: IKnownErrorDef, message?: Nullable<string>, headers?: Record<string, string>, data?: T) {
    super(def.message);
    this.code = def.code;
    this.domain = def.domain;
    this.message = message || def.message;
    this.headers = headers;
    this.statusCode = def.statusCode;
    this.data = data ?? def.data;
  }

  toResponse(status?: ErrorServerStatus): IKnownErrorResponse<T> {
    const errorDef = getKnownErrorDef(this.code)!;
    return resultKnownError(errorDef, {message: this.message, data: this.data, status, headers: this.headers});
  }
}
