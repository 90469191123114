import { SupportedLanguages } from "../models/setting";
import {Nullable} from "../types"

/**
 * Determine if a date is valid.
 * @param inputDate
 * @returns
 */
export function isValidDate(inputDate?: Nullable<Date>): inputDate is Date {
  if (!inputDate) return false;
  return !isNaN(inputDate.getTime());
}

/**
 * Format a date to a string in the format `dd/mm/yyyy` in the local timezone.
 *
 * **SERVER-SIDE**: use `formatDateTz` instead.
 *
 * @param inputDate the date or nothing
 * @returns the formatted date or an empty string
 */
export function formatDate(inputDate?: Nullable<Date>): string {
  if (!isValidDate(inputDate)) return '';

  const day = inputDate.getDate();
  const month = inputDate.getMonth() + 1;
  const year = inputDate.getFullYear();
  const dayStr = day.toString().padStart(2, '0');
  const monthStr = month.toString().padStart(2, '0');

  return `${dayStr}/${monthStr}/${year}`;
}

/**
 * Format a date to a string in the format `dd/mm/yyyy` in the specified timezone.
 *
 * @param inputDate the date or nothing
 * @param locale the locale to use (default `'nl-BE'`)
 * @param timeZone the timezone to use (default `'Europe/Brussels'`)
 * @returns
 */
export function formatDateTz(
  inputDate?: Nullable<Date>,
  locale: string = SupportedLanguages.nl,
  timeZone = 'Europe/Brussels'
): string {
  if (!isValidDate(inputDate)) return '';
  return inputDate!.toLocaleDateString(locale, {
    timeZone, day: '2-digit', month: '2-digit', year: 'numeric'
  });
}

/**
 * Format a date to a string in the format `dd/MM/yyyy hh:mm` in the specified timezone.
 *
 * @param inputDate the date or nothing
 * @param includeSeconds whether to include the seconds (default: `false`)
 * @param locale the locale to use (default `'nl-BE'`)
 * @param timeZone the timezone to use (default `'Europe/Brussels'`)
 * @returns
 */
export function formatDateTimeTz(
  inputDate?: Nullable<Date>,
  includeSeconds = false,
  locale: string = SupportedLanguages.nl,
  timeZone = 'Europe/Brussels'
): string {
  if (!isValidDate(inputDate)) return '';
  return inputDate!.toLocaleString(locale, {
    timeZone, day: '2-digit', month: '2-digit', year: 'numeric',
    hour: '2-digit', minute: '2-digit', second: includeSeconds ? '2-digit' : undefined
  }); //.replace(',', '');
}

/*
process.env.TZ = 'Etc/UTC'; // change default timezone in node.js to UTC
console.log(formatDate(new Date('2024-10-10T23:01:00.000Z'))); // returns '10/10/2024'
console.log(formatDateTz(new Date('2024-10-10T23:01:00.000Z'))); // returns '11/10/2024'
*/

/**
 * Add days to a date.
 * @param date
 * @param days
 * @returns
 */
export function addDays(date: Date | string, days: number): Date {
  const result = new Date(date);
  result.setDate(result.getDate() + Math.round(days));
  return result;
}

/**
 * Get the start of the day.
 * @param date the date (default: now)
 * @returns a new date
 */
export function getStartOfDay(date: Date | string = new Date()): Date {
  const startOfDay = new Date(date);
  startOfDay.setHours(0, 0, 0, 0);
  return startOfDay;
}

/**
 * Get the end of the day.
 * @param date the date (default: now)
 * @returns a new date
 */
export function getEndOfDay(date: Date | string = new Date()): Date {
  const endOfDay = new Date(date);
  endOfDay.setHours(23, 59, 59, 999);
  return endOfDay;
}

/**
 * Get the start and end of the day.
 * @param date the date (default: now)
 * @returns new dates
 */
export function getStartAndEndOfDay(date: Date | string = new Date()): [Date, Date] {
  return [getStartOfDay(date), getEndOfDay(date)];
}

/**
 * Get the next day.
 * @param date the date (default: now)
 * @returns a new date
 */
export function getNextDay(date: Date | string = new Date()): Date {
  return addDays(date, 1);
}

/**
 * Get the previous day.
 * @param date the date (default: now)
 * @returns a new date
 */
export function getPreviousDay(date: Date | string = new Date()): Date {
  return addDays(date, -1);
}

/**
 * Determine if two dates are the same day.
 * @param a
 * @param b
 * @returns
 */
export function isSameDay(a: Date, b: Date): boolean {
  return a.toDateString() === b.toDateString();
}

/**
 * Get the number of days between two dates.
 * @param startDate
 * @param endDate
 * @returns
 */
export function getDaysBetween(startDate: Date, endDate: Date): number {
  const oneDay = 1000 * 60 * 60 * 24;
  const startDateUtc = Date.UTC(startDate.getUTCFullYear(), startDate.getUTCMonth(), startDate.getUTCDate());
  const endDateUtc = Date.UTC(endDate.getUTCFullYear(), endDate.getUTCMonth(), endDate.getUTCDate());
  return Math.round((endDateUtc - startDateUtc) / oneDay);
}

/**
 * Returns the maximum date from a list of dates.
 * @param a the first date
 * @param b the second date
 * @param dates the rest of the dates
 * @returns the maximum date
 */
export function maxDate(a: Date, b: Date, ...dates: Array<Date>): Date {
  let max = a > b ? a : b;
  for (const date of dates) {
    if (date > max) max = date;
  }
  return max;
}

/**
 * Returns the minimum date from a list of dates.
 * @param a the first date
 * @param b the second date
 * @param dates the rest of the dates
 * @returns the minimum date
 */
export function minDate(a: Date, b: Date, ...dates: Array<Date>): Date {
  let min = a < b ? a : b;
  for (const date of dates) {
    if (date < min) min = date;
  }
  return min;
}
