import {DateTime, Id, ISearchParams, Nullable, Optional, ServerResponse, YN} from '../types';
import {CompanyType, ICompany} from './company'
import {IEmployee} from './employee';
import {SupportedLanguages} from './setting';

export enum UserRole {
  kAny = '',
  kOwner = 'owner',
  kUser = 'user'
}

export enum UserStatus {
  kActive = 'active',
  kInvited = 'invited',
  kDeleted = 'deleted'
}
export enum UserSearchStatus {
  kAll = 'all'
}
export const AnyUserStatus = {
  ...UserStatus,
  ...UserSearchStatus
}

export enum UserEvent {
  kInfectedFiles = 'infectedFiles',
  kLoggedIn = 'login',
  kLoggedOut = 'logout',
  kMailLog = 'maillog',
  kUserChanged = 'user',
  kCostChanged = 'cost',
  kSubscriptionChanged = 'subscription',
  kRegistrationChanged = 'registration',
  kNavigationChanged = 'navigation',
  kRegistrationStopped = 'registrationStopped',
  kPlannerToPlanChanged = 'plannerToPlan',
}

// from the table definition
export interface IUser {
  id: Id;
  company: Id;
  email: string;
  phone: string;
  username: string;
  name: string;
  status: UserStatus;
  mfa: YN;
  verified: YN;
  note: string;
  settings: string;
  language: SupportedLanguages;
  domains: string;
  role: UserRole;
  legal: YN;
  news: YN;
  last_seen?: DateTime;
  created?: DateTime;
}

export const kUserInviteExpiresInMs = 1000 * 60 * 60 * 24 * 7; // days
export const kEmptyUser: IUser = {
  id: 0,
  company: 0,
  username: '',
  name: '',
  phone: '',
  verified: YN.kNo,
  mfa: YN.kNo,
  status: UserStatus.kActive,
  email: '',
  note: '',
  settings: '',
  language: SupportedLanguages.nl,
  domains: '',
  role: UserRole.kUser,
  legal: YN.kNo,
  news: YN.kNo
};


//////////////////////////////////////
// Cognito responses and interfaces //
export interface IUserData {
  id?: number;
  name: string;
  email: string;
  language: SupportedLanguages;
  phone: string;
  username?: string;  // Cognito UID
  domains: string;
  legal: YN;
  news: YN;
}

export interface IRegisterData {
  email: string;
  name?: string;
  companyName?: string;
  password?: string;
  language?: SupportedLanguages;
  phone?: string;
  legal?: YN;
  news?: YN;
  type?: CompanyType;
  sector?: string;
  referral?: string;
  city?: string;
  vat_nr?: string;
  hutk?: string;
  inviteCode?: string;
  noTrial?: string;
}
export const kEmptyRegisterData: IRegisterData = {
  name: '', companyName: '', email: '', password: '', phone: '',
  language: SupportedLanguages.nl,
  legal: YN.kNo, news: YN.kNo,
  type: CompanyType.kCompany, sector: '', referral: '', city: '', vat_nr: '',
  inviteCode: '', hutk: ''
}

export interface UserLogin {
  email: string;
  password: string;
  language?: string;
}

export interface UserForgot {
  email: string;
  language?: string;
}

///////////////////////
// Server Parameters //
export enum SortUserColumnNames {
  name = 'name',
  email = 'email',
  created = 'created',
  role = 'role',
  status = 'status',
  last_seen = 'last_seen'
}

export interface IUserSearchParams extends ISearchParams {
  sort: SortUserColumnNames;
  statusFilter?: string;
}

export interface IUserInviteParams {
  name: string;
  email: string;
  phone?: string;
  employee?: Nullable<Id>;
  hour_cost?: number;
  timeItem?: Nullable<Id>;
  language?: string | SupportedLanguages;
}

export interface IUserUpsertParams extends Partial<Omit<IUser, 'default_item'>> {
  employee?: Nullable<Id>;
  default_item?: Nullable<Id>;
  hour_cost?: number;
}

export interface IUserPatchParams extends
  Partial<Pick<IUser, 'id' | 'language' | 'phone' | 'name' | 'news'>>,
  Partial<Pick<IEmployee, 'hour_cost' | 'default_item'>> {}

//////////////////////
// Server Responses //
export interface AuthResponse extends ServerResponse {
  user: IUserData
  token: string;
  expires: number;
}

export interface UserResponse extends ServerResponse {
  user: IUser;
  company: ICompany;
  employee: IEmployee;
}

export interface IUserSearchResponse extends ServerResponse {
  records: Array<IUser>;
}

/////////////
// Helpers //
////////////
export enum UserDomain {
  kNone = '',                     // Only basic access
  kAll = '*',                     // Gives access to everything
  kAdmin = 'admin',               // danger
  kTester = 'tester',             // Gives access to new modules for external testers
  kDev = 'dev',                   // Gives access to all new modules even in development
  kInternalTester = 'internalTester', // Gives access to internal testing for new modules inside the company,
  kSupport = 'support',           // Gives access to support
}

export function hasUserRole(role: Optional<string | IUser>, required: UserRole | string | ReadonlyArray<UserRole>): boolean {
  // it's a user object, grab the domains
  if (typeof role !== 'string') role = role?.role || '';

  // nothing required
  if (!required) return true;

  // owner has access to everything
  if (role === UserRole.kOwner) return true;

  // check if the role is in the required list
  const requiredArr = (typeof required === 'string') ? required.replace(/\s+/g,'').split(',') : required;
  for (let i = 0; i < requiredArr.length; i++) {
    if (requiredArr[i] === role) return true;
  }
  return false;
}

export function hasUserDomain(domains: Optional<string | IUser>, required: UserDomain | string | ReadonlyArray<UserDomain>): boolean {
  // it's a user object, grab the domains
  if (typeof domains !== 'string') domains = domains?.domains || '';

  // nothing required
  if (!required) return true;

  const domainArr = domains.replace(/\s+/g,'').split(',');
  // user with wildcard has access to everything
  if (domainArr.includes(UserDomain.kAll)) {
    // but admin access still requires explicit admin domain
    return (!required.includes(UserDomain.kAdmin) || domainArr.includes(UserDomain.kAdmin));
  }

  const requiredArr = (typeof required === 'string') ? required.replace(/\s+/g,'').split(',') : required;
  for (let i = 0; i < requiredArr.length; i++) {
    if (!domainArr.includes(requiredArr[i])) return false;
  }
  return true;
}

export function makeUserEmployee(user: IUser): IEmployee {
  return {
    company: user.company,
    language: user.language,
    name: user.name || '',
    email: user.email,
    phone1: user.phone,
    phone2: user.phone,
    street: '',  city: '',
    user: user.id,
    hour_cost: 0,
    default_item: null,
    is_active: YN.kYes
  };
}


// function test(user: string, required: string | UserDomain, expected: boolean) {
//   console.log("user: " + user + ", required: " + required + " = " + ((hasRequired(user, required) === expected) ? "ok" : "fail"));
// }
// test('admin,planning', 'admin,planning', true);
// test('*', 'admin,planning', false);
// test('*,admin,planning', UserDomain.kSupplier, true);
// test('admin,planning', UserDomain.kSupplier, false);

// test('admin', UserDomain.kPlanning, false);
// test('*', UserDomain.kPlanning, true);
// test('*,admin,planning', UserDomain.kSupplier, true);
